import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Col, Grid, Row, Rate, Message } from 'rsuite'
import logoAginpro from 'src/assets/images/logo_agin_N.png'
import backgroundImage from 'src/assets/images/FONDOB.jpg'
import Styles from './SatisfacionEncuesta.module.scss'

const SatisfacionEncuesta = () => {
  const [rating, setRating] = useState(0)
  const [enviado, setEnviado] = useState(false)

  const ingresarEncuesta = async () => {
    setEnviado(true)
  }
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minHeight: '100vh',
      }}
    >
      <Grid fluid>
        <Row align="center" className="mt-5">
          <Col className="validar-licencia-logos">
            <img className={`${Styles.Image}`} src={logoAginpro} />
          </Col>
        </Row>

        <Row className="mt-5 color-white" align="center">
          <div className={`${Styles.card}`}>
            <div className={`${Styles.cardBody}`}>
              <div className={`${Styles.cardTitle}`}>
                <h3>Califica la calidad de servicio que te a brindado nuestro asesor</h3>
              </div>
              <div className={`${Styles.cardBody}`}>
                <Col className="validar-licencia-logos">
                  <Rate defaultValue={rating} max={5} onChange={(value) => setRating(value)} />
                </Col>

                <Col className="validar-licencia-logos mt-3">
                  text
                </Col>
              </div>
              <div className={`${Styles.cardBody}`}>
                <Col className="validar-licencia-logos mt-3">
                  <Button
                    onClick={() => {
                      console.log('Confirmar', data?.status)
                      if (enviado === true) {
                        toaster.push(
                          <Message showIcon type="error">
                            Encuesta ya ingresada.
                          </Message>,
                        )
                      } else {
                        ingresarEncuesta()
                      }
                    }}
                    appearance="primary"
                    style={{
                      fontSize: '1.25rem',
                      backgroundColor: '#2833A0',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    Guardar
                  </Button>
                </Col>
              </div>
            </div>
          </div>
        </Row>
      </Grid>
    </div>
  )
}

export default SatisfacionEncuesta
