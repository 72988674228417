import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import {
  Col,
  Form,
  Row,
  ButtonToolbar,
  Button,
  InputGroup,
  AutoComplete,
  Schema,
  toaster,
  Message,
  IconButton,
  InputPicker,
  Modal,
  Loader,
  Grid,
} from 'rsuite'
import { BASE_PATH } from 'src/views/components/constants'
import TrashIcon from '@rsuite/icons/Trash'
import PlusIcon from '@rsuite/icons/Plus'
import { StringType, DateType, NumberType } from 'schema-typed'
import FormNuevoArtista from 'src/Modulos/Mantenimientos/Artista/Items/FormNuevoArtista'
import FormCancion from 'src/Modulos/Mantenimientos/Canciones/Items/FormCancion'

const FormIngresarHitsDigital = (props) => {
  const formRef = useRef()
  const initForm = {
    artista: '',
    id_artist: '',
    id_artistA: '',
    id_phonogram: '',
    id_phonogramA: '',
    honogram: '',
    id_label: '',
    id_labelA: '',
    label: '',
    id_matrix: '',
    id_matrixA: '',
    matrix: '',
  }

  const [artistas, setArtistas] = useState([])
  const [canciones, setCanciones] = useState([])
  const [loading, setloading] = useState(false)
  const [showA, setShowA] = useState(false)
  const [holders, setHolders] = useState([])
  const [showC, setShowC] = useState(false)
  const [form, setForm] = useState(initForm)
  const [productoras, setProductoras] = useState()
  const [grupos, setGrupos] = useState()

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      setloading(true)
      let res = await axios.get(
        `${BASE_PATH}/play-artists?_populate=&_limit=-1&_sort=ArtistName:ASC`,
      )
      setArtistas(res.data)

      // let resCan = await axios.get(`${BASE_PATH}/plays-phonograms?_populate=plays_playlist_hits.plays_labels.plays_matrices&_limit=-1`)
      // setCanciones(resCan.data)

      let resLabel = await axios.get(
        `${BASE_PATH}/plays-labels?_populate=plays_matrices&_limit=-1&_sort=nombre:ASC`,
      )
      setProductoras(resLabel.data)

      let resGrupo = await axios.get(
        `${BASE_PATH}/plays-matrices?_populate=&_limit=-1&_sort=matrix_name:ASC`,
      )
      setGrupos(resGrupo.data)

      let resHolder = await axios.get(
        `${BASE_PATH}/dis-holders?_populate=&_limit=-1&_sort=name:ASC`,
      )
      setHolders(resHolder.data)

      setloading(false)
    } catch (error) {
      console.log(error)
      toaster.push(
        <Message showIcon type="error" closable>
          Error, Recargar.
        </Message>,
      )
    }
  }

  const eliminarArtistaSeleccionado = () => {
    setForm({
      ...form,
      artista: '',
      id_artist: '',
      id_artistA: '',
    })
  }

  const eliminarCancionSeleccionado = () => {
    setForm({
      ...form,
      id_phonogram: '',
      id_phonogramA: '',
      honogram: '',
    })
  }

  const eliminarProductoraSeleccionado = () => {
    setForm({
      ...form,
      id_label: '',
      id_labelA: '',
      label: '',
    })
  }

  const eliminarGrupoSeleccionado = () => {
    setForm({
      ...form,
      id_matrix: '',
      id_matrixA: '',
      matrix: '',
    })
  }

  const filtroArtista = async (data) => {
    let res = await axios.get(`${BASE_PATH}/play-artists/${data}`)
    console.log('artitas', res.data)
    if (data !== form.artista) {
      setForm({
        artista: res.data.id,
        id_artist: res.data.id,
        id_artistA: res.data.id_artist,
        artistaName: res.data?.ArtistName,
        asociado: '',
        honogram: '',
        id_label: '',
        id_labelA: '',
        id_matrix: '',
        id_matrixA: '',
        id_phonogram: '',
        id_phonogramA: '',
        isrc: '',
        label: '',
        matrix: '',
        song: '',
      })
    } else {
      setForm({
        ...form,
        artista: res.data.id,
        id_artist: res.data.id,
        id_artistA: res.data.id_artist,
        artistaName: res.data?.ArtistName,
      })
    }

    let resC = await axios.get(
      `${BASE_PATH}/plays-phonograms?_populate=plays_label,plays_playlist_hits.plays_labels.plays_matrices,dis_holder&id_artist=${res.data.id_artist}&_limit=-1&_sort=Song:ASC`,
    )
    setCanciones(resC.data)
  }

  const filtroCancion = (data) => {
    let res = canciones.filter((el) => el.id === data)
    console.log('canciones', res)
    setForm({
      ...form,
      id_phonogram: res[0]?.id,
      id_phonogramA: res[0]?.id_phonogram,
      isrc: res[0]?.isrc == null ? '' : res[0]?.isrc,
      song: res[0]?.Song,
      asociado: res[0]?.dis_holder?.name == null ? '' : res[0]?.dis_holder?.name,
      honogram: res[0]?.id,
      id_label:
        res[0]?.plays_label !== null
          ? res[0]?.plays_label?.id
            ? res[0]?.plays_label.id
            : null
          : null,
      id_labelA:
        res[0]?.plays_label !== null
          ? res[0]?.plays_label?.id_label
            ? res[0]?.plays_label.id_label
            : null
          : null,
      label:
        res[0]?.plays_label !== null
          ? res[0]?.plays_label?.id
            ? res[0]?.plays_label.id
            : null
          : null,
      id_matrix:
        res[0]?.plays_playlist_hits[res[0]?.plays_playlist_hits.length - 1]?.plays_labels[
          res[0]?.plays_playlist_hits[res[0]?.plays_playlist_hits.length - 1]?.plays_labels.length -
            1
        ]?.plays_matrices[0]?.id,
      id_matrixA:
        res[0]?.plays_playlist_hits[res[0]?.plays_playlist_hits.length - 1]?.plays_labels[
          res[0]?.plays_playlist_hits[res[0]?.plays_playlist_hits.length - 1]?.plays_labels.length -
            1
        ]?.plays_matrices[0]?.id_matrix,
      matrix:
        res[0]?.plays_playlist_hits[res[0]?.plays_playlist_hits.length - 1]?.plays_labels[
          res[0]?.plays_playlist_hits[res[0]?.plays_playlist_hits.length - 1]?.plays_labels.length -
            1
        ]?.plays_matrices[0]?.id,
    })
  }

  const filtroProductora = async (data) => {
    let res = await productoras.filter((el) => el.id === data)
    var label = res[0]
    setForm({
      ...form,
      id_label: label?.id,
      id_labelA: label?.id_label,
      label: label?.id,
      id_matrix: label?.plays_matrices[0]?.id,
      id_matrixA: label?.plays_matrices[0]?.id_matrix,
      matrix: label?.plays_matrices[0]?.id,
    })
  }

  const filtroGrupo = async (data) => {
    let res = await grupos.filter((el) => el.id === data)
    var arti = res[0]
    setForm({
      ...form,
      id_matrix: arti?.id,
      id_matrixA: arti?.id_matrix,
      matrix: arti?.id,
    })
  }

  const model = Schema.Model({
    artista: NumberType().isRequired('Campo obligatorio.'),
    honogram: NumberType().isRequired('Campo obligatorio.'),
    // label: NumberType().isRequired("Campo obligatorio."),
    // matrix: NumberType().isRequired("Campo obligatorio."),
  })
  const crearHits = async () => {
    if (!formRef.current.check()) {
      toaster.push(
        <Message showIcon type="warning" closable>
          Campos requeridos vacíos.
        </Message>,
      )
      return
    } else {
      props.crearHits(form)
    }
  }

  console
  return (
    <Grid fluid>
      {loading ? (
        <div style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Loader content="Cargando..." vertical />
        </div>
      ) : (
        <Form
          fluid
          ref={formRef}
          onChange={(formValue) => {
            console.log('formValue', formValue)
            if (formValue.artista !== form.artista) {
              setForm({
                artista: '',
                artistaName: '',
                asociado: '',
                honogram: '',
                id_artist: '',
                id_artistA: '',
                id_label: '',
                id_labelA: '',
                id_matrix: '',
                id_matrixA: '',
                id_phonogram: '',
                id_phonogramA: '',
                isrc: '',
                label: '',
                matrix: '',
                song: '',
              })
            } else {
              setForm(formValue)
            }
          }}
          formValue={form}
          model={model}
        >
          <Row align="center">
            <Col md={24} sm={24} xs={24}>
              <h6>Canción y Artista tocado</h6>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={20} sm={20} xs={20}>
              <Form.ControlLabel>Artista</Form.ControlLabel>
              <Form.Control
                name={'artista'}
                accepter={InputPicker}
                data={artistas}
                onSelect={(data) => {
                  setForm({
                    honogram: null,
                  })
                  filtroArtista(data)
                }}
                valueKey="id"
                labelKey="ArtistName"
              />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Form.ControlLabel>Acciones</Form.ControlLabel>
              <abbr title="Eliminar Artista">
                <IconButton
                  appearance="subtle"
                  onClick={() => eliminarArtistaSeleccionado()}
                  icon={<TrashIcon style={{ color: 'red', cursor: 'pointer' }} />}
                />
              </abbr>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={20} sm={20} xs={20}>
              <Form.ControlLabel>Canción</Form.ControlLabel>
              <Form.Control
                block
                name={'honogram'}
                accepter={InputPicker}
                data={canciones}
                onChange={(data) => filtroCancion(data)}
                valueKey="id"
                labelKey="Song"
              />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Form.ControlLabel>Acciones</Form.ControlLabel>
              <abbr title="Eliminar Canción">
                <IconButton
                  appearance="subtle"
                  onClick={() => eliminarCancionSeleccionado()}
                  icon={<TrashIcon style={{ color: 'red', cursor: 'pointer' }} />}
                />
              </abbr>
            </Col>
            <Col md={24} sm={24} xs={24} className="mt-2">
              <Form.ControlLabel>ISRC</Form.ControlLabel>
              <Form.Control name={'isrc'} readOnly block />
            </Col>
            <Col md={24} sm={24} xs={24} className="mt-2">
              <Form.ControlLabel>Asociado</Form.ControlLabel>
              <Form.Control name={'asociado'} readOnly block />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={24} sm={24} xs={24}>
              <Form.ControlLabel>Productora</Form.ControlLabel>
              <Form.Control
                block
                name={'label'}
                accepter={InputPicker}
                data={productoras}
                onChange={(data) => filtroProductora(data)}
                valueKey="id"
                labelKey="nombre"
                readOnly
              />
            </Col>
            {/* <Col md={6} sm={6} xs={6}>
              <Form.ControlLabel>Productora</Form.ControlLabel>
              <Form.Control name={'id_labelA'} disabled />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Form.ControlLabel>Vaciar</Form.ControlLabel>
              <IconButton
                appearance="subtle"
                onClick={() => eliminarProductoraSeleccionado()}
                icon={<TrashIcon style={{ color: 'red', cursor: 'pointer' }} />}
              />
            </Col> */}
          </Row>
          <ButtonToolbar className="mt-4 rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
            <Button
              onClick={crearHits}
              appearance="primary"
              style={{ backgroundColor: '#2833A0', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
            >
              Ingresar Cancion
            </Button>
          </ButtonToolbar>

          <Modal open={showA} onClose={() => setShowA(false)}>
            <Modal.Header>
              <Modal.Title>{'Nuevo'} Artista</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Grid fluid>
                <FormNuevoArtista
                  type={'new'}
                  close={() => setShowA(false)}
                  success={() => {
                    setShowA(false)
                    getData()
                  }}
                  artista={artistas}
                />
              </Grid>
            </Modal.Body>
          </Modal>

          <Modal open={showC} onClose={() => setShowC(false)}>
            <Modal.Header>
              <Modal.Title>{'Nuevo'} Song</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Grid fluid>
                <FormCancion
                  type={'new'}
                  close={() => setShowC(false)}
                  success={() => {
                    setShowC(false)
                    getData()
                  }}
                  cancion={canciones}
                  holders={holders}
                  artistas={artistas}
                />
              </Grid>
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </Grid>
  )
}

export default FormIngresarHitsDigital
