import React, { Component, useReducer, useEffect } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import './scss/style.scss'
import 'rsuite/dist/rsuite.min.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
import { AuthContext } from './auth/AuthContext'
import { AuthReducer } from './auth/AuthReducer'
import ValidarLicencia from './views/pages/ValidarLicencia'
import ValidarEntrada from './views/pages/ValidarEntrada'
import AdminEncuesta from './Modulos/Plays/EncuestaDigital/AdminEncuesta'
import SatisfacionEncuesta from './views/pages/SatisfacionEncuesta'

const init = () => {
  return JSON.parse(localStorage.getItem('user')) || { logged: false }
}

function App() {
  const [user, dispatch] = useReducer(AuthReducer, {}, init)

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user))
  }, [user])
  return (
    <AuthContext.Provider value={{ user, dispatch }} className="notranslate">
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/validar-licencia/:id/:unit"
              name="Verificar Licencia"
              render={(props) => <ValidarLicencia {...props} />}
            />
            <Route
              exact
              path="/validar-licencia/:id"
              name="Verificar Licencia"
              render={(props) => <ValidarLicencia {...props} />}
            />
            <Route
              exact
              path="/ingresar-encuesta/:id"
              name="Ingreso de encuesta"
              render={(props) => <AdminEncuesta {...props} />}
            />

            <Route
              exact
              path="/validar-entrada"
              name="Verificar Entrada"
              render={(props) => <ValidarEntrada {...props} />}
            />
            <Route
              exact
              path="/encuesta-satisfaccion"
              name="encuesta de satisfaccion"
              render={(props) => <SatisfacionEncuesta {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </AuthContext.Provider>
  )
}

export default App
