import axios from 'axios';
import React, { useRef, useState } from 'react'
import { Button, ButtonToolbar, Col, Form, Message, Row, Schema, toaster } from 'rsuite'
import { StringType } from "schema-typed";
import { BASE_PATH } from 'src/Constant';

const FormNuevoArtista = (props) => {
    const formRef = useRef()
    const initForm = {
        ArtistName: props.type == 'edit' ? props.artista.ArtistName : ''
    }
    const [form, setForm] = useState(initForm)

    const model = Schema.Model({
        ArtistName: StringType().isRequired("Campo obligatorio."),
    })

    const onSave = async () => {
        if (!formRef.current.check()) {
            toaster.push(
                <Message showIcon type="warning" closable>
                    Campos requeridos vacíos.
                </Message>
            );
            return
        } else {
            try {
                let res = await axios.post(`${BASE_PATH}/play-artists`, form)
                props.success()
                toaster.push(
                    <Message showIcon type="success" closable>
                        Artista ingresado con exito.
                    </Message>
                );
            } catch (error) {
                console.log(error)
            }
        }
    }

    const onEdit = async () => {
        if (!formRef.current.check()) {
            toaster.push(
                <Message showIcon type="warning" closable>
                    Campos requeridos vacíos.
                </Message>
            );
            return
        } else {
            try {
                let res = await axios.put(`${BASE_PATH}/play-artists/${props.artista.id}`, form)
                props.success()
                toaster.push(
                    <Message showIcon type="success" closable>
                        Artista editado con exito.
                    </Message>
                );
            } catch (error) {
                console.log(error)
            }
        }
    }
    return (
        <>
            <Form
                fluid
                ref={formRef}
                onChange={(formValue) => setForm(formValue)}
                model={model}
                formValue={form}
            >
                <Row>
                    <Col md={24} sm={24} xs={24}>
                        <Form.ControlLabel>Nombre Artista</Form.ControlLabel>
                        <Form.Control name={"ArtistName"} />
                    </Col>
                </Row>
                <ButtonToolbar className="mt-4 rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                    <Button
                        onClick={props.type == 'edit' ? onEdit : onSave} 
                        appearance="primary"
                        style={{ backgroundColor: "#2833A0", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                    >
                        {props.type == 'edit' ? 'Editar' : 'Ingresar'}
                    </Button>
                    <Button onClick={() => props.close()} appearance="default">
                        Cancelar
                    </Button>
                </ButtonToolbar>
            </Form>
        </>
    )
}

export default FormNuevoArtista