import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Grid, Row } from 'rsuite'
import logoIfpi from 'src/assets/images/IFPI-white.png'
import logoAginpro from 'src/assets/images/logo_agin_N.png'
import gif from 'src/assets/images/chek.png'
import logomusicartes from 'src/assets/images/musicartes2.png'
import backgroundImage from 'src/assets/images/FONDOB.jpg'
import Styles from './ValidarEntrada.module.scss'

const ValidarEntrada = (props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minHeight: '100vh',
      }}
    >
      <Grid fluid>
        <Row align="center" className="mt-5">
          <Col className="validar-licencia-logos">
            <img className={`${Styles.Image}`} src={logoAginpro} />
          </Col>
        </Row>

        <Row className="mt-5 color-white" align="center">
          <Col md={24} xs={24} sm={24}>
            <h1 className={`validar-licencia-title ${Styles.Title}`}>
              Agente Autorizado para Monitorear Eventos
            </h1>
          </Col>
        </Row>

        <Row className="mt-5 color-white" align="center">
          <Col md={24} xs={24} sm={24}>
            <h1 className={`validar-licencia-title ${Styles.Title}`}>AGINPRO 2022</h1>
          </Col>
        </Row>

        <Row align='center' className='mt-4'>
          <Col md={24} sm={24} xs={24}> 
            <img src={gif} style={{height: '250px', width: '250px'}}/>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default ValidarEntrada
