import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import {
  Col,
  Grid,
  Panel,
  Row,
  ButtonToolbar,
  Button,
  Modal,
  toaster,
  Message,
  Form,
  TagPicker,
  Input,
  Rate,
} from 'rsuite'
import { BASE_PATH } from 'src/Constant'
import logoAginpro from 'src/assets/images/aginpro.jpg'
import moment from 'moment'
import TableCore from 'src/Components/TableCore'
import FormIngresarHitsDigital from './Items/FormIngresarHitsDigital'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Styles from './SatisfacionEncuesta.module.scss'

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />)
const AdminEncuesta = (props) => {
  const formRef = useRef()
  const formRefEva = useRef()
  const formRefRepro = useRef()
  const initForm = {
    status: true,
  }
  const [form, setForm] = useState(initForm)
  const [showSave, setShowSave] = useState(false)

  const initFormR = {
    fuentes_reproduccions: [],
    indicacionesF: '',
    indicacionesTV: '',
    indicacionesRadio: '',
  }
  const [formReproduccion, setFormReproduccion] = useState(initFormR)

  const history = useHistory()
  useEffect(() => {
    getData()
  }, [])

  const [data, setData] = useState(null)
  const [fuentes, setFuentes] = useState([])
  const [canciones, setCanciones] = useState([])
  const [show, setShow] = useState(false)
  const [loading, setloading] = useState(false)

  const getData = async () => {
    try {
      let res = await axios.get(`${BASE_PATH}/plays-playlists/${props?.match?.params?.id}`)
      setData(res.data)
      console.log('res', res.data)

      let resF = await axios.get(`${BASE_PATH}/fuentes-reproduccions`)
      setFuentes(resF.data)
      console.log('resF', resF.data)
    } catch (error) {
      console.log(error)
    }
  }

  const crearHits = (value) => {
    let array = []
    let list = canciones
    console.log('canciones', value)

    setCanciones([
      ...canciones,
      {
        ...value,
        index: 1,
        artista: value?.artistaName,
        cancion: value?.song,
      },
    ])
    setShow(false)
  }

  const ingresarEncuesta = async () => {
    if (canciones.length > 0 && canciones.length < 20) {
      try {
        setloading(true)
        await canciones.map(async (item) => {
          let res = await axios.post(`${BASE_PATH}/play-artist-hits`, {
            id_phonogram: item.id_phonogramA,
            id_artist: item.id_artistA,
            id_playlist: data.id_playlist,
            id_label: item.id_labelA,
            plays_playlists: data.id != null && [`${data.id}`],
            plays_phonograms: item.id_phonogram != null && [`${item.id_phonogram}`],
            plays_artists: item.id_artist != null && [`${item.id_artist}`],
            plays_labels: item.id_label != null && [`${item.id_label}`],
          })
        })

        let resPlays = await axios.put(`${BASE_PATH}/plays-playlists/${props?.match?.params?.id}`, {
          ...form,
          fuentes_reproduccions: formReproduccion.fuentes_reproduccions,
          indicacionesF: formReproduccion?.indicacionesF,
          indicacionesTV: formReproduccion?.indicacionesTV,
          indicacionesRadio: formReproduccion?.indicacionesRadio,
        })

        let resEva = await axios.post(`${BASE_PATH}/evaluacions`, {
          calificacion: evaluacion.puntaje,
          plays_playlist: props?.match?.params?.id,
          comentario: evaluacion.comentario,
        })
        toaster.push(
          <Message showIcon type="success" closable>
            Encuesta ingresada con exito
          </Message>,
        )
        setloading(false)
        history.push(`/lista-plays`)
      } catch (error) {
        console.log('error')
        toaster.push(
          <Message showIcon type="error" closable>
            Error de servidor
          </Message>,
        )
      }
    } else {
      toaster.push(
        <Message showIcon type="warning" closable>
          Ingresar canciones
        </Message>,
      )
    }
  }

  console.log('canciones', canciones)
  console.log('form', formReproduccion)

  const [evaluacion, setEvaluacion] = useState({
    puntaje: 0,
    comentario: '',
  })

  return (
    <Grid fluid>
      <Panel style={{ backgroundColor: '#fff' }}>
        <Row align="center">
          <Col className="validar-licencia-logos">
            <img className="validar-licencia-logoAginpro" src={logoAginpro} />
          </Col>
        </Row>
        <Row className="mt-2" align="center">
          <Col md={24} sm={24} xs={24}>
            <h5>
              {data?.plays_playlist_types[0]?.playlist_type} {moment(data?.Begin).format('YYYY')}
            </h5>
          </Col>
        </Row>
        <Row className="mt-4" align="center">
          <Col md={24} sm={24} xs={24}>
            <h6>Razón Social: {data?.lead_accounts[0]?.Empresa}</h6>
          </Col>
        </Row>

        {data?.status === true ? (
          <>
            <Row className="mt-2">
              <Col
                xs={24}
                md={24}
                sm={24}
                align="center"
                style={{ backgroundColor: '#2833A0', color: '#fff', height: '50px' }}
                className="mt-2"
              >
                <h5 style={{ paddingTop: '10px', color: 'white' }}>Encuesta Completada</h5>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Form
              fluid
              ref={formRef}
              onChange={(formValue) => setForm(formValue)}
              // model={model}
              formValue={form}
            >
              <Row className="mt-2" align="center">
                <Col md={12} sm={12} xs={12}>
                  <Form.ControlLabel>
                    <strong>Nombre</strong>
                  </Form.ControlLabel>
                  <Form.Control block name={'nEncargado'} />
                </Col>
                <Col md={12} sm={12} xs={12}>
                  <Form.ControlLabel>
                    <strong>Cargo</strong>
                  </Form.ControlLabel>
                  <Form.Control block name={'cargo'} />
                </Col>
              </Row>
              <Row align="center" className="mt-2">
                <Col md={24} sm={12} xs={24}>
                  <Form.ControlLabel>
                    <strong>DPI</strong>
                  </Form.ControlLabel>
                  <Form.Control name={'dpi'} style={{ width: '50%' }} />
                </Col>
              </Row>
            </Form>

            <Row className="mt-2">
              <Col
                xs={24}
                md={24}
                sm={24}
                align="center"
                style={{ backgroundColor: '#2833A0', color: '#fff', height: '50px' }}
                className="mt-2"
              >
                <h5 style={{ paddingTop: '10px', color: 'white' }}>Fuentes de reproducción</h5>
              </Col>
            </Row>

            <Form
              fluid
              ref={formRefRepro}
              onChange={(formValue) => setFormReproduccion(formValue)}
              // model={model}
              formValue={formReproduccion}
            >
              <Row className="mt-2" align="center">
                <Col md={24} sm={24} xs={24}>
                  <Form.ControlLabel>
                    <strong>Seleccionar fuentes de reproducción</strong>
                  </Form.ControlLabel>
                  <Form.Control
                    data={fuentes}
                    accepter={TagPicker}
                    style={{ width: '100%' }}
                    name="fuentes_reproduccions"
                    value={formReproduccion.fuentes_reproduccions}
                    valueKey="id"
                    labelKey="name"
                  />
                </Col>
              </Row>

              {formReproduccion.fuentes_reproduccions.includes(7) && (
                <Col md={12} xs={24} className="mt-2">
                  <Form.ControlLabel>
                    <strong>*Indicaciones Fuente</strong>
                  </Form.ControlLabel>
                  <Form.Control accepter={Textarea} rows={3} name="indicacionesF" />
                </Col>
              )}
              {formReproduccion.fuentes_reproduccions.includes(5) && (
                <Col md={12} xs={24} className="mt-2">
                  <Form.ControlLabel>
                    <strong>Indicaciones TV</strong>
                  </Form.ControlLabel>
                  <Form.Control accepter={Textarea} rows={3} name="indicacionesTV" />
                </Col>
              )}
              {formReproduccion.fuentes_reproduccions.includes(6) && (
                <Col md={12} xs={24} className="mt-2">
                  <Form.ControlLabel>
                    <strong>Indicaciones Radio</strong>
                  </Form.ControlLabel>
                  <Form.Control accepter={Textarea} rows={3} name="indicacionesRadio" />
                </Col>
              )}
            </Form>

            <Row className="mt-2">
              <Col
                xs={24}
                md={24}
                sm={24}
                align="center"
                style={{ backgroundColor: '#2833A0', color: '#fff', height: '50px' }}
                className="mt-2"
              >
                <h5 style={{ paddingTop: '10px', color: 'white' }}>Encuesta de asesor</h5>
              </Col>
            </Row>
            <Row className="mt-5 color-white" align="center">
              <Form
                fluid
                ref={formRefEva}
                onChange={(formValue) => setEvaluacion(formValue)}
                formValue={evaluacion}
              >
                <Row className="mt-1" align="center">
                  <Col md={24} sm={24} xs={24}>
                    <Form.ControlLabel style={{ color: 'black' }}>
                      <strong>
                        Califica la calidad de servicio que te a brindado nuestro asesor
                      </strong>
                    </Form.ControlLabel>

                    <Form.Control accepter={Rate} max={5} name="puntaje" />
                  </Col>
                  <Col md={24} sm={24} xs={24} align="center">
                    <Form.ControlLabel style={{ color: 'black', width: '500px' }}>
                      <strong>Comentario </strong>
                    </Form.ControlLabel>
                    <Form.Control
                      accepter={Textarea}
                      rows={3}
                      name="comentario"
                      style={{ width: '500px' }}
                    />
                  </Col>
                </Row>
              </Form>
            </Row>

            <Row className="mt-2">
              <Col
                xs={24}
                md={24}
                sm={24}
                align="center"
                style={{ backgroundColor: '#2833A0', color: '#fff', height: '50px' }}
                className="mt-2"
              >
                <h5 style={{ paddingTop: '10px', color: 'white' }}>
                  Indique las canciones de artistas que mas se tocan en su establecimiento
                </h5>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col md={24} sm={24} xs={24}>
                <h5
                  style={{
                    width: '100 %',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  Este listado de popularidad de artistas guatemaltecos asociados con Aginpro o
                  Musicartes, nos sirve para saber a quien distribuir lo que pagas por tu licencia.
                  Gracias por contribuir al desarrollo de la industria de la musica local
                </h5>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={11} sm={11} xs={11}>
                <Button
                  disabled={loading ? true : false}
                  onClick={() => setShow(true)}
                  appearance="primary"
                  style={{
                    backgroundColor: '#2833A0',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  Ingresar canción
                </Button>
              </Col>
              <Col md={13} sm={13} xs={13}>
                <h5>
                  Faltan <strong>{20 - canciones.length} canciones.</strong>
                </h5>
              </Col>
            </Row>

            <Row align="center" className="mt-4">
              <Col md={24} sm={24} xs={24}>
                <TableCore
                  data={canciones}
                  type="encuesta-canciones"
                  messageLoader="Ingresando la encuesta, espere un momento"
                ></TableCore>
              </Col>
            </Row>

            <Row align="center">
              <Col md={24} sm={24} xs={24}>
                <Button
                  disabled={loading ? true : false}
                  onClick={
                    () => setShowSave(true)
                    // ingresarEncuesta
                  }
                  appearance="primary"
                  style={{
                    backgroundColor: '#2833A0',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  Ingresar Encuesta
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Panel>
      <Modal open={show} onClose={() => setShow(false)} size={'xs'}>
        <Modal.Header>
          <Modal.Title>Registrar canciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            <FormIngresarHitsDigital data={data} crearHits={crearHits} />
          </Grid>
        </Modal.Body>
      </Modal>

      <Modal open={showSave} onClose={() => setShowSave(false)} size="xs">
        <Modal.Header>
          <Modal.Title>Ingresar Encuesta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Grid fluid>
            <Message showIcon type="warning">
              Esta seguro de ingresar la encuesta?
            </Message>

            <Row align="center">
              <Col xs={12}>
                <ButtonToolbar className="mt-4 rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                  <Button
                    onClick={() => {
                      console.log('Confirmar', data?.status)
                      if (data?.status === true) {
                        toaster.push(
                          <Message showIcon type="error">
                            Encuesta ya ingresada.
                          </Message>,
                        )
                        setShowSave(false)
                      } else {
                        ingresarEncuesta()
                      }
                    }}
                    appearance="primary"
                    style={{
                      backgroundColor: '#2833A0',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    Guardar
                  </Button>
                </ButtonToolbar>
              </Col>
              <Col xs={12}>
                <ButtonToolbar className="mt-4 rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                  <Button
                    onClick={() => {
                      console.log('Cancelar')
                      setShowSave(false)
                    }}
                    appearance="subtle"
                    style={{
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    Cancelar
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
      </Modal>
    </Grid>
  )
}

export default AdminEncuesta
