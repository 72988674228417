import axios from 'axios'
import { BASE_PATH } from 'src/views/components/constants'
import moment from 'moment'

//Recent activity
export const getLeadStatus = async () => {
  let resultLeadStatus = await axios.get(`${BASE_PATH}/lead-statuses?_fields=id,id_status,Status`)
  if (resultLeadStatus.status === 200) {
    let statusLead = resultLeadStatus.data.map((item) => {
      return {
        label: item.Status,
        value: item.id,
      }
    })
    return statusLead
  }
  return false
}

//Lead Lead
export const getActivityRecent = async (filter = '') => {
  let resActivity = await axios.get(`${BASE_PATH}/lead-leads${filter}&_populate=lead_accounts,lead_portfolios,lead_license_cats,lead_statuses,lead_followups`)
  if (resActivity.status === 200) {
    let { data } = resActivity
    let dataActivity = data.map((item) => {
      return {
        NombreCom: item.lead_accounts.length > 0 ? item.lead_accounts[0].NombreCom : '',
        Empresa: item.lead_accounts.length > 0 ? item.lead_accounts[0].Empresa : '',
        Cartera: item.lead_portfolios.length ? item.lead_portfolios[0].Cartera : '',
        License_Name:
          item.lead_license_cats.length > 0 ? item.lead_license_cats[0].License_Name : '',
        Periodicidad: item.Periodicidad,
        precio_negocio: parseFloat(item.precio_negocio).toFixed(2),
        Cantidad: item.Cantidad,
        potencial: parseFloat(item.precio_negocio * item.Cantidad).toFixed(2),
        Status:
          item.lead_statuses.length > 0
            ? `${item.lead_statuses[0].Status} por ${moment().diff(item.timestamp, 'days')} Dias`
            : '',
        Descripcion: item.Descripcion,
        Followup:
          item.lead_followups.length > 0
            ? item.lead_followups[item.lead_followups.length - 1].Followup
            : '',
        timestamp: item.timestamp,
        id: item.id,
        id_lead: item.id_lead == null ? item.id : item.id_lead,
        company: item.lead_accounts,
      }
    })
    return dataActivity
  }
  return false
}

export const createLeadLead = async (data, usuario) => {
  let resCreateLead = await axios.post(`${BASE_PATH}/lead-leads`, data)

  if (resCreateLead.status === 200) {
    let crearBitacora = await axios.post(`${BASE_PATH}/bitacora-leads`, {
      Date: moment().format('YYYY-MM-DD'),
      cambio: 'Creación de prospecto',
      tipo: 'prospecto',
      users_id: usuario.id_user,
      id_lead: resCreateLead.data.id_lead,
      id_account: resCreateLead.data.lead_accounts[0]?.id_account,
      users_permissions_users: [`${usuario.users}`],
      lead_accounts: [`${resCreateLead.data.lead_accounts[0]?.id}`],
      lead_leads: [`${resCreateLead.data.id}`],
      datos: resCreateLead.data,
    })
  }
  return resCreateLead.status == 200 ? resCreateLead.data : false
}

export const getLeadId = async (id) => {
  let resleadId = await axios.get(`${BASE_PATH}/lead-leads/${id}`)
  if (resleadId.status === 200) {
    let { data } = resleadId
    // console.log(data);
    let res = {
      Cartera: data?.lead_portfolios[0]?.id,
      License_Name: data.lead_license_cats[0].id,
      Status: data.lead_statuses[0].id,
      Cantidad: data.Cantidad,
      Periodicidad: data.Periodicidad,
      precio_negocio: data.precio_negocio,
      Descripcion: data.Descripcion,
      Empresa: data.lead_accounts[0].Empresa,
      user: data.users_permissions_users.length > 0 ? data.users_permissions_users[0].id : '',
      userName:
        data.users_permissions_users.length > 0 ? data.users_permissions_users[0].username : '',
      comments: '',
      details:
        data.lead_followups.length > 0
          ? data.lead_followups[data.lead_followups.length - 1].Followup
          : '',
      id_account: data.lead_accounts[0].id,
      dataCompany: data.lead_accounts[0],
      dataWallet: data.lead_portfolios[0],
      Followup: data.lead_followups,
      licenses_category: data.lead_license_cats[0],
      tipo_carteras: data.tipo_carteras.length > 0 ? data.tipo_carteras[0]?.id : null,
      tamanioCartera: data?.tipo_carteras.length > 0 ? data?.tipo_carteras[0].tamanio : '',
      medidaCartera:
        data.lead_portfolios[0].lead_license_units.length > 0
          ? data.lead_portfolios[0].lead_license_units[0].id
          : null,
      licenses: data.lead_licenses,
      statusData: data.lead_statuses[0],
      id: data.id,
      id_lead: data.id_lead,
      Comentario: data.Comentario,
      lead_portfolios: data.lead_portfolios[0],
      cuenta: data.cuenta,
      tarifaAginpro: data.tarifaAginpro,
      categoria_tarifas: data.categoria_tarifas.length > 0 ? data.categoria_tarifas[0].id : null,
      lead_license_units: data.lead_license_units.length > 0 ? data.lead_license_units[0].id : null,
      lead_accounts: data?.lead_accounts[0],
      tipo_seguimiento: data?.tipo_seguimiento?.id,
      files_pdfs: data?.files_pdfs
    }
    return res
  }
  return false
}

export const getLeadOptimizacion = async (id) => {
  let resleadId = await axios.get(
    `${BASE_PATH}/lead-leads?id=${id}&_populate=lead_portfolios,lead_license_cats,lead_statuses,lead_accounts,users_permissions_users,lead_followups`,
  )
  if (resleadId.status === 200) {
    let data = resleadId.data[0]
    let res = {
      Cartera: data.lead_portfolios[0].id,
      License_Name: data.lead_license_cats[0].id,
      Status: data.lead_statuses[0].id,
      Cantidad: data.Cantidad,
      Periodicidad: data.Periodicidad,
      precio_negocio: data.precio_negocio,
      Descripcion: data.Descripcion,
      Empresa: data.lead_accounts[0].Empresa,
      user: data.users_permissions_users.length > 0 ? data.users_permissions_users[0].id : '',
      comments: '',
      details:
        data.lead_followups.length > 0
          ? data.lead_followups[data.lead_followups.length - 1].Followup
          : '',
      id_account: data.lead_accounts[0].id,
      dataCompany: data.lead_accounts[0],
      dataWallet: data.lead_portfolios[0],
      Followup: data.lead_followups,
      licenses_category: data.lead_license_cats[0],
      licenses: data.lead_licenses,
      statusData: data.lead_statuses[0],
      id: data.id,
      id_lead: data.id_lead,
      Comentario: data.Comentario,
      lead_portfolios: data.lead_portfolios[0],
    }
    return res
  }
  return false
}

export const updateLeadId = async (id, data, usuario) => {
  let resUpdateLead = await axios.put(`${BASE_PATH}/lead-leads/${id}`, data)
  // console.log("usuario", usuario)
  if (resUpdateLead.status === 200) {
    console.log(resUpdateLead.data)
    let crearBitacora = await axios.post(`${BASE_PATH}/bitacora-leads`, {
      Date: moment().format('YYYY-MM-DD'),
      cambio: 'Actualización de prospecto',
      users_id: usuario.id_user,
      tipo: 'prospecto',
      id_lead: resUpdateLead.data.id_lead,
      id_account: resUpdateLead.data.lead_accounts[0]?.id_account,
      users_permissions_users: [`${usuario.users}`],
      lead_leads: [`${resUpdateLead.data.id}`],
      lead_accounts: [`${resUpdateLead.data.lead_accounts[0]?.id}`],
      datos: {
        Cantidad: resUpdateLead.data.Cantidad,
        Comentario: resUpdateLead.data.Comentario,
        Descripcion: resUpdateLead.data.Descripcion,
        Periodicidad: resUpdateLead.data.Periodicidad,
        created_at: resUpdateLead.data.created_at,
        cuenta: resUpdateLead.data.cuenta,
        id: resUpdateLead.data.id,
        id_Executive: resUpdateLead.data.id_Executive,
        id_Status: resUpdateLead.data.id_Status,
        id_account: resUpdateLead.data.id_account,
        id_cartera: resUpdateLead.data.id_cartera,
        id_lead: resUpdateLead.data.id_lead,
        id_license_Cat: resUpdateLead.data.id_license_Cat,
        lead_accounts: resUpdateLead.data.lead_accounts,
        lead_license_cats: resUpdateLead.data.lead_license_cats,
        lead_portfolios: resUpdateLead.data.lead_portfolios,
        lead_statuses: resUpdateLead.data.lead_statuses,
        precio_negocio: resUpdateLead.data.precio_negocio,
        published_at: resUpdateLead.data.published_at,
        timestamp: resUpdateLead.data.timestamp,
        updated_at: resUpdateLead.data.updated_at,
        users_permissions_users: resUpdateLead.data.users_permissions_users,
      },
    })
  }
  return resUpdateLead.status === 200 ? resUpdateLead.data : false
}

export const updateLeadIdVencimiento = async (id, data, usuario) => {
  let resUpdateLead = await axios.put(`${BASE_PATH}/lead-leads/${id}`, data)
  console.log('usuario', usuario)
  if (resUpdateLead.status === 200) {
    let crearBitacora = await axios.post(`${BASE_PATH}/bitacora-leads`, {
      Date: moment().format('YYYY-MM-DD'),
      cambio: 'Vencimiento procesado.',
      users_id: usuario?.id_user,
      tipo: 'prospecto',
      id_lead: resUpdateLead.data.id_lead,
      id_account: resUpdateLead.data.lead_accounts[0]?.id_account,
      users_permissions_users: [`${usuario?.users}`],
      lead_leads: [`${resUpdateLead.data.id}`],
      lead_accounts: [`${resUpdateLead.data.lead_accounts[0]?.id}`],
      datos: resUpdateLead.data,
    })
  }
  return resUpdateLead.status === 200 ? resUpdateLead.data : false
}

export const getLeadLeadQuery = async (query) => {
  const resLeadQuery = await axios.get(`${BASE_PATH}/lead-leads${query}`)
  return resLeadQuery.status == 200 ? resLeadQuery.data : false
}

//Lead Portfolios
export const getLeadPortfolios = async () => {
  let resultLeadPort = await axios.get(`${BASE_PATH}/lead-portfolios?_fields=id,id_cartera,Cartera`)
  if (resultLeadPort.status === 200) {
    let leadPort = resultLeadPort.data.map((item) => {
      return {
        label: item.Cartera,
        value: item.id,
      }
    })
    return leadPort
  }
  return false
}

export const getAllLeadPortfolios = async () => {
  const query =
    'lead-portfolios?_populate=tipo_carteras.lead_license_units&_populate=tipo_carteras.categoria_tarifas&_populate=lead_license_units&_populate=categoria_tarifas'
  const resp = await axios.get(`${BASE_PATH}/${query}`)

  return resp.status === 200 ? resp.data : false
}

export const getLeadPortfoliosOld = async () => {
  let resultLeadPort = await axios.get(`${BASE_PATH}/lead-portfolios?_populate`)
  if (resultLeadPort.status === 200) {
    let leadPort = resultLeadPort.data.map((item) => {
      return {
        label: item.Cartera,
        value: item.id_cartera,
      }
    })
    return leadPort
  }
  return false
}

export const getLeadPortfoliosId = async (id) => {
  let resultLeadPortId = await axios.get(`${BASE_PATH}/lead-portfolios/${id}`)
  return resultLeadPortId.status === 200 ? resultLeadPortId.data : false
}

//Lead Account
export const getLeadAccount = async (search) => {
  let resultLeadAccount = await axios.get(
    `${BASE_PATH}/lead-accounts?Empresa_contains=${search}&_fields=id,Empresa`,
  )
  if (resultLeadAccount.status === 200) {
    let leadAccount = resultLeadAccount.data.map((item) => {
      return {
        label: item.Empresa,
        value: item.id,
      }
    })
    return leadAccount
  }
  return false
}
export const getLeadAccountCom = async (search, filter) => {
  let resultLeadAccount = await axios.get(
    `${BASE_PATH}/lead-accounts?${filter}_contains=${search}&_populate=&_limit=-1`,
  )
  if (resultLeadAccount.status === 200) {
    let leadCommerce = resultLeadAccount.data.map((item) => {
      return item.NombreCom
    })
    let leadCompany = resultLeadAccount.data.map((item) => {
      return item.Empresa
    })
    return {
      leadCommerce,
      leadCompany,
    }
  }
  return false
}

export const getLeadAccounts = async (search, filter) => {
  console.log(filter)
  let resultLeadAccount = await axios.get(
    `${BASE_PATH}/lead-accounts?${filter}_contains=${search}&_populate=&_limit=-1`,
  )
  if (resultLeadAccount.status === 200) {
    let leadCommerce = resultLeadAccount.data.map((item) => {
      return item.NombreCom
    })
    let leadCompany = resultLeadAccount.data.map((item) => {
      return item.Empresa
    })
    if (filter === 'Empresa') {
      return leadCompany
    } else {
      return leadCommerce
    }
  }
  return false
}

export const getLeadAccountId = async (id) => {
  let resAccountId = await axios.get(`${BASE_PATH}/lead-accounts/${id}`)
  return resAccountId.status === 200 ? resAccountId.data : false
}

export const createLeadAccount = async (data, usuario) => {
  let respCreateAccount = await axios.post(`${BASE_PATH}/lead-accounts`, data)

  if (respCreateAccount.status === 200) {
    let crearBitacora = await axios.post(`${BASE_PATH}/bitacora-leads`, {
      Date: moment().format('YYYY-MM-DD'),
      cambio: 'Creación de la cuenta',
      tipo: 'cuenta',
      id_users: usuario.id_user,
      id_account: respCreateAccount.data.id_account,
      users_permissions_users: [`${usuario.users}`],
      lead_accounts: [`${respCreateAccount.data.id}`],
      datos: {
        Direccion_Fisica: respCreateAccount?.Direccion_Fisica,
              Direccion_Legal: respCreateAccount.Direccion_Legal,
              Empresa: respCreateAccount?.Empresa,
              Info: respCreateAccount?.Info,
              NIT: respCreateAccount?.NIT,
              NombreCom: respCreateAccount?.NombreCom,
              Telefono_1: respCreateAccount?.Telefono_1,
              Telefono_2: respCreateAccount?.Telefono_2,
              company_email: respCreateAccount?.company_email,
              contraseniaP: respCreateAccount?.contraseniaP,
              created_at: respCreateAccount?.created_at,
              departamento: respCreateAccount?.departamento,
              dia_cobro: respCreateAccount?.dia_cobro,
              dia_entrega: respCreateAccount?.dia_entrega,
              dias_credito: respCreateAccount?.dias_credito,
              entrega_factura: respCreateAccount?.entrega_factura,
              forma_pago: respCreateAccount?.forma_pago,
              horario_entrega: respCreateAccount?.horario_entrega,
              id: respCreateAccount?.id,
              id_account: respCreateAccount?.id_account,
              municipio: respCreateAccount?.municipio,
              orden_compra: respCreateAccount?.orden_compra,
              persona_pago: respCreateAccount?.persona_pago,
              published_at: respCreateAccount?.published_at,
              retencion: respCreateAccount?.retencion,
              retencionT: respCreateAccount?.retencionT,
              telefono_persona: respCreateAccount?.telefono_persona,
              updated_at: respCreateAccount?.updated_at,
              users_permissions_user: respCreateAccount?.users_permissions_user,
              zona: respCreateAccount?.zona
      },
    })
  }
  return respCreateAccount.status === 200 ? respCreateAccount.data : false
}
export const getLeadsAccount = async (filter) => {
  let resLeadsAccount = await axios.get(`${BASE_PATH}/lead-accounts${filter}`)
  if (resLeadsAccount.status == 200) {
    let users = resLeadsAccount.data.map((item) => {
      return {
        ...item,
        telefonos: `${item.Telefono_1} / ${item.Telefono_2 != null ? item.Telefono_2 : ''} `,
      }
    })
    return users
  }
  return []
}

export const getLeadAccountQuery = async (query) => {
  const resLeadAccount = await axios.get(`${BASE_PATH}/lead-accounts${query}`)
  return resLeadAccount.status == 200 ? resLeadAccount.data : false
}

//Lead Licenses Category
export const getLeadLicensesCategory = async () => {
  let resultLicensesCategory = await axios.get(
    `${BASE_PATH}/lead-license-cats?_fields=id_licenseCat,id,License_Name`,
  )
  if (resultLicensesCategory.status === 200) {
    let licensesCategory = resultLicensesCategory.data.map((item) => {
      return {
        label: item.License_Name,
        value: item.id,
      }
    })
    return licensesCategory
  }
  return false
}

export const getLeadLicensesCategoryId = async (id) => {
  let resLicenseCat = await axios.get(`${BASE_PATH}/lead-license-cats/${id}`)
  return resLicenseCat.status === 200 ? resLicenseCat.data : false
}

//Lead Followup
export const createFollowup = async (data) => {
  let resCreateFollowup = await axios.post(`${BASE_PATH}/lead-followups`, data)
  return resCreateFollowup.status == 200 ? resCreateFollowup.data : false
}

export const getFollowups = async (query) => {
  const resFollowups = await axios.get(`${BASE_PATH}/lead-followups${query}`)
  return resFollowups.status == 200 ? resFollowups.data : false
}

export const updateFollowupsId = async (id, data) => {
  const updateFollowup = await axios.put(`${BASE_PATH}/lead-followups/${id}`, data)
  return updateFollowup.status == 200 ? updateFollowup.data : false
}

//LEAD ACCOUNT CONTACT
export const getAccountContact = async (filters) => {
  let resAccountContact = await axios.get(`${BASE_PATH}/lead-account-contacts${filters}`)
  if (resAccountContact.status == 200) {
    let contacts = resAccountContact.data.map((item) => {
      return {
        ...item,
        Empresa: item.lead_accounts[0].Empresa,
        NombreCom: item.lead_accounts[0].NombreCom,
      }
    })
    return contacts
  }
  return []
}

//LEAD PORTAFOLIO - CARTERA
export const getLeadPortafolio = async (query) => {
  const resLeadPortafolio = await axios.get(`${BASE_PATH}/lead-portfolios${query}`)
  return resLeadPortafolio.status == 200 ? resLeadPortafolio.data : false
}

//LEAD UNITS
export const getLeadUnits = async (query) => {
  const resLeadUnits = await axios.get(`${BASE_PATH}/lead-units${query}`)
  return resLeadUnits.status == 200 ? resLeadUnits.data : false
}

export const updateLeadUnits = async (id, data) => {
  const resUpdateLeadUnits = await axios.put(`${BASE_PATH}/lead-units/${id}`, data)
  return resUpdateLeadUnits.status == 200 ? resUpdateLeadUnits.data : false
}

//LEAD REPORTE
export const getReportLeadData = async (query = '') => {
  const resp = await axios.get(`${BASE_PATH}/reporte-lead?lead_statuses.id=5${query}`)
  return resp.status === 200 ? resp.data : false
}

//LEAD REPORTE CON ESTATUS
export const getReportLeadDataStatus = async (query) => {
  const resp = await axios.get(
    `${BASE_PATH}/lead-leads?&_sort=id:DESC&_populate=lead_statuses,lead_portfolios,lead_accounts,users_permissions_users&_fields=id,Periodicidad,precio_negocio,timestamp,lead_portfolios.id,lead_portfolios.id_cartera,lead_portfolios.Cartera,lead_statuses.id,lead_statuses.id_status,lead_statuses.Status,users_permissions_users.id,users_permissions_users.username,users_permissions_users.user_id,lead_accounts${query}`,
  )
  return resp.status === 200 ? resp.data : false
}
