export const verifyStepLimit = (nextStep, limit) => {
    return nextStep < 0 ? 0 : nextStep > limit ? limit : nextStep;
};

export const calculateSumary = (array, key) => {
    let sumTotal = array.reduce((acc, item)=> {
        if(item[key] != ''){
            return acc + parseFloat(item[key])
        }
        return acc
    }, 0)
    return sumTotal
} 

export function separator(numb = "") {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return  str.join(".");
}