import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Grid, Row } from 'rsuite'
import logoIfpi from 'src/assets/images/IFPI-white.png'
import logoAginpro from 'src/assets/images/logo_agin_N.png'
import logomusicartes from 'src/assets/images/musicartes2.png'
import backgroundImage from 'src/assets/images/FONDOB.jpg'
import { getLeadUnits } from 'src/Services/LeadServices'

import { BASE_PATH } from 'src/Constant'

const ValidarLicencia = (props) => {
  const [licencia, setLicencia] = useState()
  const [LicenceUnit, setLicenceUnit] = useState()
  useEffect(() => {
    getLicencia()
  }, [])
  console.log('props', props)

  const style = {
    width: '40%',
    // height: '80px',
  }
  const styleP = {
    fontSize: '20px',
  }

  const getLicencia = async () => {
    if (props.match.params.unit) {
      if (props.match.params.unit !== 'null') {
        const dataUnidad = await getLeadUnits(`/${props.match.params.unit}`)
        setLicenceUnit(dataUnidad)
      }
    }
    let res = await axios.get(`${BASE_PATH}/lead-licenses/${props.match.params.id}`)
    setLicencia(res.data)
  }

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',

        minHeight: '100vh',
      }}
    >
      <Grid fluid>
        <Row align="center" className="mt-5">
          <Col className="validar-licencia-logos">
            <img className="validar-licencia-logoIFPI" src={logoIfpi} />
            <img className="validar-licencia-logoAginpro" src={logoAginpro} />
            <img className="validar-licencia-logoMusicartes" src={logomusicartes} />
          </Col>
        </Row>
        <Row className="mt-4 color-white" align="center">
          <Col md={24} xs={24} sm={24}>
            <h3 className="validar-licencia-title">Licencia Válida</h3>
          </Col>
          <Col md={24} xs={24} sm={24} className="mt-4 validar-licencia-mt">
            <p style={styleP}>
              <b>Comercio:</b>{' '}
            </p>
          </Col>
          <Col md={24} xs={24} sm={24}>
            <p style={styleP}>{licencia?.NombreLicense}</p>
          </Col>
          <Col md={24} xs={24} sm={24} className="validar-licencia-mt">
            <p style={styleP}>
              <b>Ubicacion:</b>{' '}
            </p>
          </Col>
          <Col md={24} xs={24} sm={24}>
            <p style={styleP}>
              {props?.match?.params?.unit !== null
                ? LicenceUnit?.Ubic !== undefined
                  ? LicenceUnit?.Ubic
                  : licencia?.Ubicacion
                : licencia?.Ubicacion}
            </p>
          </Col>
          <Col md={24} xs={24} sm={24} className="validar-licencia-mt">
            <p style={styleP}>
              <b>Tamaño:</b>
            </p>
          </Col>
          <Col md={24} xs={24} sm={24}>
            <p style={styleP}>
              {/* {licencia?.Size} {licencia?.lead_license_units[0]?.UnidadMedida} */}
              {props?.match?.params?.unit !== null
                ? LicenceUnit?.lead_leads[0]?.Cantidad
                  ? LicenceUnit?.lead_leads[0]?.Cantidad
                  : licencia?.Size
                : licencia?.Size}
              {licencia?.lead_license_units[0]?.UnidadMedida}
            </p>
          </Col>
          <Col md={24} xs={24} sm={24} className="validar-licencia-mt">
            <p style={styleP}>
              <b>Periodo:</b>
            </p>
          </Col>
          <Col md={24} xs={24} sm={24}>
            <p style={styleP}>
              {/* {licencia?.Periodicidad} */}
              {props?.match?.params?.unit !== null
                ? LicenceUnit?.lead_leads[0]?.Periodicidad !== undefined
                  ? LicenceUnit?.lead_leads[0]?.Periodicidad
                  : licencia?.Periodicidad
                : licencia?.Periodicidad}
            </p>
          </Col>
          <Col md={24} xs={24} sm={24} className="validar-licencia-mt">
            <p style={styleP}>
              <b>Licencia Válida</b>
            </p>
          </Col>
          <Col md={24} xs={24} sm={24} className="validar-licencia-mb">
            <p style={styleP}>
              <b>Desde: </b> {licencia?.Begin}&nbsp;&nbsp;&nbsp;&nbsp;
              <b>&nbsp;&nbsp;&nbsp;&nbsp;Hasta: </b> {licencia?.End}
            </p>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default ValidarLicencia
