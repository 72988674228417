import React, { useContext, useEffect, useState } from 'react'
import { IconButton, Input, InputPicker, Loader } from 'rsuite'
import EditIcon from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import { FaPrint as PrintIcon } from 'react-icons/fa'
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import configTables from '../../Configs/configTables.json'
import { CalculateSumary } from 'src/Utils/CalculateSumary'
import { separator } from 'src/Modulos/Global/Function'
import VisibleIcon from '@rsuite/icons/Visible'
import UnvisibleIcon from '@rsuite/icons/Unvisible'
import EmailFillIcon from '@rsuite/icons/EmailFill'
import CopyIcon from '@rsuite/icons/Copy'
import { AuthContext } from 'src/auth/AuthContext'
import NoticeIcon from '@rsuite/icons/Notice'
import CheckIcon from '@rsuite/icons/Check'
import ReloadIcon from '@rsuite/icons/Reload';

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />)

const style = {
  tabla: {
    Height: '600px !important',
    overflowY: 'auto',
  },
}

const TableCore = ({
  data,
  song,
  notificacion,
  readNotification,
  onReadNotification,
  type,
  onRedirect,
  isAction,
  isSend,
  onSend,
  isCopy,
  onCopy,
  isEdit,
  isActive,
  isView,
  onActive,
  isEmail,
  isDelete,
  usuarios,
  isPrint,
  onEdit,
  onDelete,
  onPrint,
  onView,
  onUser,
  onEmail,
  isTotal,
  onNotificacion,
  totalsKey,
  onEvent,
  isTotalRow,
  isTotalMultiple,
  totalsKeyMultiple,
  loading,
  messageLoader,
  bordered,
  onSong,
  songV,
  onSongValidar,
  recargar,
  onRecargar
}) => {
  const { user } = useContext(AuthContext)

  return (
    <div>
      {loading && <Loader backdrop content={messageLoader} size="lg" className="zIndex-100" />}
      <CTable responsive className="table-core-responsive" bordered={bordered}>
        <CTableHead className="table-core-head">
          <CTableRow>
            <>
              {configTables[type]?.map((col, i) => {
                return (
                  <CTableHeaderCell scope="col" key={`row-${col.key}`}>
                    {col.label}
                  </CTableHeaderCell>
                )
              })}

              {isAction && (
                <CTableHeaderCell scope="col" key={`action`}>
                  Acciones
                </CTableHeaderCell>
              )}
            </>
          </CTableRow>
        </CTableHead>

        <CTableBody>
          {data.map((item, i) => {
            return (
              <CTableRow>
                {configTables[type]?.map((col) => {
                  return (
                    <CTableHeaderCell key={col.key} style={{ wordBreak: 'normal' }}>
                      {col.redirect || col.event ? (
                        col.href ? (
                          <a className="fontClick" href={`${item[col.key].split('+/')[1]}`}>
                            {item[col.key].split('+/')[0]}
                          </a>
                        ) : col.link ? (
                          <a className="fontClick" target="_blank" href={`${item?.url}`}>
                            {item[col.key]?.split('+/')[0]}
                          </a>
                        ) : (
                          <a className="fontClick" onClick={() => onRedirect(item)}>
                            {item[col.key]?.split('+/')[0]}
                          </a>
                        )
                      ) : col.event ? (
                        <p className="fontClick" onClick={() => onEvent(item)}>
                          {item[col.key]}
                        </p>
                      ) : col.event2 ? (
                        <p className="fontClick" onClick={() => onEvent(item)}>
                          {item[col.key]}
                        </p>
                      ) : col.list ? (
                        item[col.key].length > 0 ? (
                          item[col.key].map((el) => <li>{el}</li>)
                        ) : (
                          '-'
                        )
                      ) : col.texTarea ? (
                        item[col.key] != null ? (
                          <Input
                            readOnly
                            style={{ width: '200px' }}
                            as="textarea"
                            rows={9}
                            value={item[col.key]}
                          />
                        ) : (
                          '-'
                        )
                      ) : col.edit ? (
                        <InputPicker
                          data={usuarios.filter((el) => el.id != 'null')}
                          readOnly={user.data.user.role.name != 'Gestion' ? false : true}
                          style={{ width: '500px' }}
                          valueKey="id"
                          labelKey="username"
                          value={item[col.key]}
                          onChange={(value) => onUser(value, item)}
                        />
                      ) : song ? (
                        (item?.status) ? (
                          <Input
                            // readOnly
                            style={{ color: 'red' }}
                            // as="textarea"
                            // rows={9}
                            value={item[col.key]}
                            onChange={(value) => onSong(value, i, col.key)}
                          />
                          // <p style={{ color: 'red' }}>{item[col.key]}</p>
                        ) : (
                          item[col.key]
                        )
                      ) : (
                        item[col.key]
                      )}
                    </CTableHeaderCell>
                  )
                })}

                {isAction && (
                  <CTableHeaderCell key={'actionButton'}>
                    <div>
                      {isEdit && (
                        <abbr title="Editar">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onEdit(item)}
                              icon={<EditIcon style={{ color: 'blue' }} />}
                            />
                          </a>
                        </abbr>
                      )}
                      {isDelete && (
                        <abbr title="Eliminar">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onDelete(item)}
                              icon={<TrashIcon style={{ color: 'red' }} />}
                            />
                          </a>
                        </abbr>
                      )}
                      {isPrint && (
                        <abbr title="Imprimir">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onPrint(item)}
                              icon={<PrintIcon style={{ color: 'blue' }} />}
                            />
                          </a>
                        </abbr>
                      )}
                      {isView && (
                        <abbr title="Ver">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onView(item)}
                              icon={<VisibleIcon style={{ color: 'blue' }} />}
                            />
                          </a>
                        </abbr>
                      )}
                      {isSend && (
                        <abbr title="Enviar">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onSend(item)}
                              icon={<EmailFillIcon style={{ color: 'blue' }} />}
                            />
                          </a>
                        </abbr>
                      )}
                      {isCopy && (
                        <abbr title="Enviar">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onCopy(item)}
                              icon={<CopyIcon style={{ color: 'blue' }} />}
                            />
                          </a>
                        </abbr>
                      )}
                      {isActive && (
                        <abbr title="Mostrar / Ocultar">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onActive(item)}
                              icon={
                                item.status ? (
                                  <UnvisibleIcon style={{ color: 'blue' }} />
                                ) : (
                                  <VisibleIcon style={{ color: 'blue' }} />
                                )
                              }
                            />
                          </a>
                        </abbr>
                      )}
                      {readNotification && item.license !== '' && (
                        <abbr title="Marcar notificacion como Leida">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onReadNotification(item)}
                              icon={<EditIcon style={{ color: 'blue' }} />}
                            // icon={item.tipo === 'seguimiento' && <EditIcon style={{ color: 'blue' }} />}
                            />
                          </a>
                        </abbr>
                      )}
                      {notificacion && (
                        <abbr title="Notificacion">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onNotificacion(item)}
                              icon={<NoticeIcon style={{ color: 'orange' }} />}
                            />
                          </a>
                        </abbr>
                      )}
                      {recargar && (
                        <abbr title="Validar cancion">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onRecargar(item, i)}
                              icon={<ReloadIcon />}
                            />
                          </a>
                        </abbr>
                      )}
                      {songV && (
                        <abbr title="Validar cancion">
                          <a style={{ cursor: 'pointer' }}>
                            <IconButton
                              appearance="subtle"
                              onClick={() => onSongValidar(item, i)}
                              icon={<CheckIcon style={{ color: 'green' }} />}
                            />
                          </a>
                        </abbr>
                      )}
                    </div>
                  </CTableHeaderCell>
                )}
              </CTableRow>
            )
          })}

          {isTotal && (
            <CTableRow>
              {isTotalRow && <CTableHeaderCell>{`Total: ${data.length}`}</CTableHeaderCell>}
              {!isTotalMultiple &&
                configTables[type]?.map((col) => {
                  return (
                    <CTableHeaderCell>
                      {col.totals
                        ? `Q.${separator(
                          parseFloat(
                            CalculateSumary(data, totalsKey ? totalsKey : col.key),
                          ).toFixed(2),
                        )}`
                        : ''}{' '}
                      {col.sumatoria ? CalculateSumary(data, totalsKey ? totalsKey : col.key) : ''}
                    </CTableHeaderCell>
                  )
                })}
              {isTotalMultiple &&
                configTables[type]?.map((col) => {
                  return (
                    <CTableHeaderCell>
                      {col.totals
                        ? `Q.${separator(
                          parseFloat(
                            CalculateSumary(data, totalsKeyMultiple[col.summaryIndex]),
                          ).toFixed(2),
                        )}`
                        : ''}
                    </CTableHeaderCell>
                  )
                })}
            </CTableRow>
          )}
        </CTableBody>
      </CTable>
    </div>
  )
}

TableCore.defaultProps = {
  bordered: false,
  data: [],
  type: '',
  onRedirect: () => { },
  isAction: false,
  song: false,
  isEdit: false,
  isDelete: false,
  isPrint: false,
  notificacion: false,
  isEmail: false,
  onNotificacion: () => { },
  onEdit: () => { },
  onDelete: () => { },
  onPrint: () => { },
  onView: () => { },
  onEmail: () => { },
  onSongValidar: () => { },
  songV: false,
  recargar: false,
  onRecargar: () => { },
  isSend: false,
  onSend: () => { },
  isTotal: false,
  totalsKey: false,
  onEvent: () => { },
  isTotalRow: false,
  isTotalMultiple: false,
  totalsKeyMultiple: [],
  loading: false,
  messageLoader: '',
  onUser: () => { },
}

export default TableCore
