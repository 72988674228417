import axios from 'axios';
import React, { useRef, useState } from 'react'
import { Button, ButtonToolbar, Col, Form, Message, Row, Schema, toaster, InputPicker } from 'rsuite'
import { StringType, NumberType } from "schema-typed";
import { BASE_PATH } from 'src/Constant';

const FormCancion = (props) => {
    const formRef = useRef()
    const initForm = {
        Song: props.type == 'edit' ? props.cancion.Song : '',
        isrc: props.type == 'edit' ? props.cancion.isrc : '',
        plays_artists: props.type == 'edit' ? props?.cancion?.plays_artists[0]?.id : null,
        dis_holder: props.type == 'edit' ? props?.cancion?.dis_holder?.id : null,
        plays_label: props.type == 'edit' ? props?.cancion?.plays_label?.id : null
    }
    const [form, setForm] = useState(initForm)

    const model = Schema.Model({
        Song: StringType().isRequired("Campo obligatorio."),
        isrc: StringType().isRequired("Campo obligatorio."),
        plays_artists: NumberType().isRequired("Campo obligatorio."),
        // dis_holder: NumberType().isRequired("Campo obligatorio."),
        // plays_label: NumberType().isRequired("Campo obligatorio.")
    })

    const onSelect = async (value) => {
        try {
            let res = await axios.get(`${BASE_PATH}/play-artists/${value}`)
            setForm({ ...form, id_artist: res.data.id_artist, plays_artists: res.data.id })
        } catch (error) {
            console.log(error)
        }
    }

    const onSelectHol = async (value) => {
        try {
            let res = await axios.get(`${BASE_PATH}/dis-holders/${value}`)
            setForm({ ...form, dis_holder: res.data.id })
        } catch (error) {
            console.log(error)
        }
    }

    const onSave = async () => {
        if (!formRef.current.check()) {
            toaster.push(
                <Message showIcon type="warning" closable>
                    Campos requeridos vacíos.
                </Message>
            );
            return
        } else {
            try {
                let res = await axios.post(`${BASE_PATH}/plays-phonograms`, form)
                props.success()
                toaster.push(
                    <Message showIcon type="success" closable>
                        Song ingresada con exito.
                    </Message>
                );
            } catch (error) {
                console.log(error)
            }
        }
    }


    const onEdit = async () => {
        if (!formRef.current.check()) {
            toaster.push(
                <Message showIcon type="warning" closable>
                    Campos requeridos vacíos.
                </Message>
            );
            return
        } else {
            try {
                let res = await axios.put(`${BASE_PATH}/plays-phonograms/${props.cancion.id}`, form)
                props.success()
                toaster.push(
                    <Message showIcon type="success" closable>
                        Song editada con exito.
                    </Message>
                );
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <Form
            fluid
            ref={formRef}
            onChange={(formValue) => setForm(formValue)}
            model={model}
            formValue={form}
        >
            <Row>
                <Col md={24} sm={24} xs={24}>
                    <Form.ControlLabel>Song</Form.ControlLabel>
                    <Form.Control name={"Song"} />
                </Col>
                <Col md={24} sm={24} xs={24}>
                    <Form.ControlLabel>ISRC</Form.ControlLabel>
                    <Form.Control name={"isrc"} />
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col md={24} sm={24} xs={24}>
                    <Form.ControlLabel>Artista</Form.ControlLabel>
                    <Form.Control name={"plays_artists"} accepter={InputPicker} data={props.artistas} onChange={(value) => onSelect(value)} valueKey="id" labelKey="ArtistName" placeholder="Seleccionar" />
                </Col>
                <Col md={24} sm={24} xs={24}>
                    <Form.ControlLabel>Socio</Form.ControlLabel>
                    <Form.Control name={"dis_holder"} accepter={InputPicker} data={props.holders} valueKey="id" labelKey="name" placeholder="Seleccionar" onChange={(value) => onSelectHol(value)} />
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col md={24} sm={24} xs={24}>
                    <Form.Group >
                        <Form.ControlLabel>Productora</Form.ControlLabel>
                        <Form.Control block name={"plays_label"} accepter={InputPicker} data={props.productoras}  valueKey="id" labelKey="nombre" placeholder="Seleccionar" />
                    </Form.Group>
                </Col>
            </Row>
            <ButtonToolbar className="mt-4 rs-flex-box-grid rs-flex-box-grid-bottom rs-flex-box-grid-center">
                <Button
                    onClick={props.type == 'edit' ? onEdit : onSave}
                    appearance="primary"
                    style={{ backgroundColor: "#2833A0", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                >
                    {props.type == 'edit' ? 'Editar' : 'Ingresar'}
                </Button>
                <Button onClick={() => props.close()} appearance="default">
                    Cancelar
                </Button>
            </ButtonToolbar>
        </Form>
    )
}

export default FormCancion